import React, { useState } from "react";
import { NavLink } from "react-router-dom";

import componentConfig from "../config";
import "./Sidebar.css";

export default function Sidebar() {
    const [sidebar, setSidebar] = useState(false); // To toggle Sidebar

    const changeSidebar = () => {
        setSidebar(!sidebar);
        document.getElementById("listy").classList.toggle("active");
        if (document.getElementById("container-second")) {
            document.getElementById("container-second").classList.toggle("active");
        }
    };

    return (
        <div id="sidebar" className={sidebar ? "sidebar active" : "sidebar"}>
            <div className="toggle-btn" onClick={changeSidebar}>
                <span></span>
                <span></span>
                <span></span>
            </div>
            <ul className="listy" id="listy">
                <li className="listItem first">
                    <NavLink className="linky" to="/" onClick={changeSidebar}>
                        Home
                    </NavLink>
                </li>
                {componentConfig.map((e) => (
                    <li key={e.name} className="listItem">
                        <NavLink className="linky" to={`/${e.name.toLowerCase()}`} onClick={changeSidebar}>
                            {e.name}
                        </NavLink>
                    </li>
                ))}
            </ul>
        </div>
    );
}
