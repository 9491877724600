import React from "react";
import { Button, OverlayTrigger, Popover } from "react-bootstrap";

const exceptKeys = ["__v", "_id", "isActive", "createdAt", "updatedAt"];

export default function PopoverComponent({ data, column, viewRefData }) {
    let popoverData =
        viewRefData &&
        viewRefData[column.objKey] &&
        viewRefData[column.objKey].data &&
        viewRefData[column.objKey].data.find((x) => x._id === data[column.objKey]);
    return (
        popoverData && (
            <OverlayTrigger
                rootClose
                trigger="click"
                placement="bottom"
                overlay={
                    <Popover id="popover-basic">
                        <Popover.Title as="h3">{popoverData.name}</Popover.Title>
                        <Popover.Content>
                            {Object.keys(popoverData).map((k) =>
                                k !== "name" &&
                                popoverData[k] &&
                                !exceptKeys.includes(k) &&
                                !Array.isArray(popoverData[k]) &&
                                !viewRefData.hasOwnProperty(k) ? (
                                    <p key={k}>
                                        {k.charAt(0).toUpperCase() + k.slice(1)} : <b>{popoverData[k]}</b>
                                    </p>
                                ) : (
                                    ""
                                )
                            )}
                        </Popover.Content>
                    </Popover>
                }
            >
                <Button variant="outline-dark">
                    {column.format
                        .split(" - ")
                        .map((e) => popoverData[e])
                        .join(" - ")}
                </Button>
            </OverlayTrigger>
        )
    );
}
