import React, { Component, Fragment } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import "./styles/App.css";
import Home from "./pages/Home";
import ViewData from "./pages/ViewData";
import Navbar from "./components/Navbar";
import Sidebar from "./components/Sidebar";
import componentConfig from "./components/config";

class App extends Component {
    render() {
        return (
            <Fragment>
                <Navbar />
                <Sidebar />
                <Switch>
                    <Route exact path="/" component={Home} />
                    {componentConfig.map((e) => (
                        <Route
                            key={e.name}
                            exact
                            path={`/${e.name.toLowerCase()}`}
                            component={() => <ViewData targetView={e.name} />}
                        />
                    ))}
                    <Redirect to="/" />
                </Switch>
            </Fragment>
        );
    }
}

export default App;
