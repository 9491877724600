import axios from "axios";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import React from "react";
import { Button, Form, Modal, Alert } from "react-bootstrap";

import { baseUrl } from "../../common/apiRoutes";

export default function FormComponent(props) {
    let {
        targetView,
        show,
        viewData,
        setViewData,
        viewRefData,
        setViewRefData,
        errorMessage,
        setErrorMessage,
        handleClose,
        fetchData,
    } = props;
    const { register, handleSubmit, reset } = useForm();
    const history = useHistory();

    function getFormControl(column) {
        switch (column.type) {
            case "referencedSchema":
                return (
                    <Form.Control
                        as="select"
                        defaultValue="Choose..."
                        name={column.objKey}
                        {...register(column.objKey, { shouldUnregister: true })}
                    >
                        {viewRefData.hasOwnProperty(column.objKey) &&
                            viewRefData[column.objKey].hasOwnProperty("data") &&
                            viewRefData[column.objKey].data.map((v, i) => (
                                <option key={i} value={v._id}>
                                    {v.code} - {v.name}
                                </option>
                            ))}
                    </Form.Control>
                );
            case "select":
                return (
                    <Form.Control
                        as="select"
                        defaultValue="Choose..."
                        name={column.objKey}
                        {...register(column.objKey, { shouldUnregister: true })}
                    >
                        {column.values.map((v) => (
                            <option key={v} value={v}>
                                {v}
                            </option>
                        ))}
                    </Form.Control>
                );
            default:
                return (
                    <Form.Control
                        type={column.type}
                        name={column.objKey}
                        {...register(column.objKey, {
                            shouldUnregister: true,
                        })}
                    />
                );
        }
    }

    const onSubmit = async (data) => {
        try {
            if (viewData && viewData.modelConfig) {
                viewData.modelConfig.map((column) => {
                    if (column.type === "array" && data.hasOwnProperty(column.objKey)) {
                        data[column.objKey] = data[column.objKey].split(",").map((e) => e.trim());
                    }
                    return data;
                });
            }
            await axios.post(baseUrl + targetView.toLowerCase(), data);
            setErrorMessage("");
            handleClose();
            reset();
            history.push("/" + targetView.toLowerCase());
            const response = await fetchData(targetView.toLowerCase());
            setViewData(response.res);
            setViewRefData(response.resRefData);
        } catch (error) {
            if (error.response) {
                console.log(error.response);
                setErrorMessage(error.response.data.message);
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log("Error", error.message);
            }
        }
    };

    return (
        <Modal show={show} onHide={handleClose} animation={false}>
            <Modal.Header closeButton>
                <Modal.Title>Add New {targetView}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    {viewData.modelConfig &&
                        viewData.modelConfig.map(
                            (column, index) =>
                                (column.hasOwnProperty("input") ? column.input : true) && (
                                    <Form.Group key={index}>
                                        <Form.Label>{column.name}</Form.Label>
                                        {getFormControl(column)}
                                    </Form.Group>
                                )
                        )}
                    {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
                    <Button variant="info" type="submit" className="mr-2">
                        Submit
                    </Button>
                    <Button variant="info" onClick={handleClose}>
                        Cancel
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    );
}
