import React, { Fragment } from "react";
import "./Card.css";
// import { NavLink } from "react-router-dom";
/* <NavLink to="/#">ORDER NOW!</NavLink> */

export default function Card({ viewData, viewRefData }) {
    let body = [];
    let headers = [];
    let renderData = [];
    if (viewData && viewData.data) {
        viewData.modelConfig.map(
            (e) =>
                e.type !== "referencedSchema" &&
                (e.hasOwnProperty("view") ? e.view !== "no" : true) &&
                (e.view === "heading" ? headers.push(e) : body.push(e))
        );
        renderData = viewData.data;
    }
    return renderData.map((e, i) => (
        <Fragment key={i}>
            <div key={"div" + i} className="card item">
                <ul key={"ul" + i} type="none" className="item-list">
                    <div key={"div-header" + i} className="item-header">
                        {headers.map((h) => (
                            <div key={"div" + i + h.objKey} className={h.objKey === "name" ? "title" : h.objKey}>
                                {e[h.objKey]}
                            </div>
                        ))}
                    </div>

                    {body.map((b) => (
                        <li key={"li" + i + b.objKey}>
                            {b.name}:<strong key={"strong" + i + b.objKey}>{e[b.objKey] ? e[b.objKey] : "NA"}</strong>
                        </li>
                    ))}
                </ul>
                <div key={"div-footer" + i} className="border" />
            </div>
        </Fragment>
    ));
}
