import React from "react";

export default function Navbar() {
    return (
        <nav className="navbar fixed-top navbar-expand-lg navbar-dark bg-dark">
            <a className="navbar-brand" href="/">
                IMS
            </a>
            <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarNav"
                aria-controls="navbarNav"
                aria-expanded="false"
                aria-label="Toggle navigation"
            >
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarNav">
                <ul className="navbar-nav mr-auto">
                    <li className="nav-item dropdown pl-3">
                        <a
                            className="nav-link dropdown-toggle"
                            href="/#"
                            id="navbarDropdown"
                            role="button"
                            data-toggle="dropdown"
                            aria-expanded="false"
                        >
                            Market
                        </a>

                        <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                            <a className="dropdown-item" href="/#">
                                Action
                            </a>
                            <a className="dropdown-item" href="/#">
                                Another action
                            </a>
                            <div className="dropdown-divider"></div>
                            <a className="dropdown-item" href="/#">
                                Something else here
                            </a>
                        </div>
                    </li>
                    <li className="nav-item dropdown pl-2">
                        <a
                            className="nav-link dropdown-toggle"
                            href="/#"
                            id="navbarDropdownMenuLink"
                            data-toggle="dropdown"
                            aria-expanded="false"
                        >
                            Vendor
                        </a>
                        <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                            <a className="dropdown-item" href="/#">
                                Action
                            </a>
                            <a className="dropdown-item" href="/#">
                                Another action
                            </a>
                            <a className="dropdown-item" href="/#">
                                Something else here
                            </a>
                        </div>
                    </li>
                </ul>
                <form className="form-inline my-2 my-lg-0">
                    <input className="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search" />
                    <button className="btn btn-outline-success my-2 my-sm-0" type="submit">
                        Search
                    </button>
                </form>
            </div>
        </nav>
    );
}
