import { Table } from "react-bootstrap";
import PopoverComponent from "../Misc/Popover";

export default function TableComponent({ viewData, viewRefData }) {
    function getTableCell(data, column) {
        let key = column.objKey;
        switch (column.type) {
            case "array":
                return data[key] && data[key].join(", ");
            case "referencedSchema":
                if (Array.isArray(data[key])) {
                    //todo view overlay and list all array items (e.g. stock of items)
                } else {
                    let refData =
                        viewRefData.hasOwnProperty(key) &&
                        viewRefData[key].hasOwnProperty("data") &&
                        viewRefData[key].data &&
                        viewRefData[key].data.find((x) => x._id === data[key]);
                    return refData && <PopoverComponent data={data} column={column} viewRefData={viewRefData} />;
                }
                break;
            default:
                return data[key];
        }
    }
    return (
        <Table striped bordered hover responsive>
            <thead>
                <tr>
                    {viewData.modelConfig &&
                        viewData.modelConfig.map(
                            (column, i) =>
                                (column.hasOwnProperty("view") ? column.view === "no" : true) && (
                                    <th key={i}>{column.name}</th>
                                )
                        )}
                </tr>
            </thead>
            <tbody>
                {viewData.data &&
                    viewData.data.map((e, i) => (
                        <tr key={"tr" + i}>
                            {viewData.modelConfig &&
                                viewData.modelConfig.map(
                                    (column, j) =>
                                        (column.hasOwnProperty("view") ? column.view === "no" : true) && (
                                            <td key={"td" + j}>{getTableCell(e, column)}</td>
                                        )
                                )}
                        </tr>
                    ))}
            </tbody>
        </Table>
    );
}
